import React, { ReactNode } from "react";
import {
	cn,
	Content,
	ContentProps,
	DataObserveKey,
	Divider,
	useUniqueId,
	VisualComponent,
} from "@siteimprove/fancylib";
import { BaseDialog } from "../base-dialog/base-dialog";
import { DialogHeaderProps, DialogHeader } from "../dialog-header/dialog-header";
import * as scss from "./modal.scss";

type ModalProps = {
	shown: boolean;
	children: ReactNode;
	onUnmount?: () => void;
	size?: "small" | "medium" | "fullWidth";
	hideHeader?: true;
	"aria-labelledby"?: string;
	headerTitle?: React.ReactNode;
} & VisualComponent &
	Omit<DialogHeaderProps, "headerTitle"> &
	DataObserveKey;

export function Modal(props: ModalProps): JSX.Element {
	const {
		shown,
		headerTitle,
		headerTitleTooltipText,
		headerTitleTooltipPlacement,
		headerIcon,
		children,
		onClose,
		hideClose,
		size = "small",
		onUnmount,
		className,
		style,
		hideHeader,
	} = props;
	const labelId = useUniqueId("modal");

	return (
		<BaseDialog
			data-component={"modal"}
			data-observe-key={props["data-observe-key"]}
			className={cn(
				className,
				scss.modal,
				size == "small" && scss[size],
				size == "medium" && scss[size],
				shown ? scss.modalEntry : scss.modalExit
			)}
			style={style}
			onUnmount={onUnmount}
			onClose={onClose}
			shown={shown}
			aria-labelledby={props["aria-labelledby"] ?? labelId}
			noCloseOnBackdrop
		>
			{!hideHeader && (
				<DialogHeader
					id={labelId}
					headerIcon={headerIcon}
					headerTitle={headerTitle}
					headerTitleTooltipText={headerTitleTooltipText}
					headerTitleTooltipPlacement={headerTitleTooltipPlacement}
					onClose={onClose}
					hideClose={hideClose}
				/>
			)}
			{children}
		</BaseDialog>
	);
}

function ModalContent(props: ContentProps): JSX.Element {
	return (
		<div className={cn(scss.modalContent)}>
			<Content {...props}>{props.children}</Content>
		</div>
	);
}

Modal.Content = ModalContent;

export interface FooterProps {
	children: React.ReactNode;
}

function ModalFooter({ children }: FooterProps): JSX.Element {
	return (
		<div className={scss.modalFooter}>
			<Divider />
			{children}
		</div>
	);
}

Modal.Footer = ModalFooter;
