import React, { ReactNode } from "react";
import {
	InlineText,
	VisualComponent,
	FocusableComponent,
	DataObserveKey,
	RequireOne,
	WithOptional,
	cn,
	f2u,
} from "@siteimprove/fancylib";
import { FormControl } from "../form/form";
import * as scss from "./checkbox.scss";

export type BaseCheckboxProps = Omit<WithOptional<FormControl<string>, "value">, "onChange"> & {
	/** Determines if the checkbox appears checked or not */
	checked: boolean;
	/** The value of the checkbox when used in a form */
	value?: string;
	/** Event for when the state of the checkbox changes */
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	/** Disables the checkbox, prevent it from changing state */
	disabled?: boolean;
	/** Shows the checkbox as neither checked nor unchecked */
	indeterminate?: boolean;
} & DataObserveKey &
	VisualComponent &
	FocusableComponent;

export function BaseCheckbox(props: BaseCheckboxProps): JSX.Element {
	const { name, value, onChange, checked, invalid, disabled, indeterminate, className, ...rest } =
		props;

	return (
		<input
			data-component="checkbox"
			name={name}
			value={value}
			type="checkbox"
			className={cn(scss.checkbox, invalid && scss.invalid, className, "fancy-Checkbox")}
			onChange={onChange}
			checked={checked}
			aria-checked={indeterminate ? "mixed" : checked}
			aria-invalid={invalid}
			disabled={disabled}
			ref={(element) => element && (element.indeterminate = !!indeterminate)}
			{...rest}
		/>
	);
}

export type CheckboxProps = RequireOne<BaseCheckboxProps, "value"> & {
	children: ReactNode;
};

export function Checkbox(props: CheckboxProps): JSX.Element {
	const { checked, disabled, children, ...rest } = props;

	const renderContent = (): React.ReactNode => {
		if (
			// string like children
			typeof children === "string" ||
			// number like children
			typeof children === "number"
		) {
			return (
				<InlineText
					lineHeight="multi-line"
					emphasis={checked ? "medium" : "normal"}
					tone={f2u(disabled && "subtle")}
				>
					{children}
				</InlineText>
			);
		}

		// function like children
		if (typeof children === "function") {
			return children({ checked, disabled });
		}

		return children;
	};

	return (
		<label data-component="form-checkbox" className={scss.checkboxLabel}>
			<BaseCheckbox checked={checked} disabled={disabled} {...rest} />
			{renderContent()}
		</label>
	);
}

/* --- CheckboxGroup Context --- */
type CheckboxGroupContextProps = {
	name?: string;
	onBlur?: React.FocusEventHandler;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	selectedValues: string[];
};

const CheckboxGroupContext = React.createContext<CheckboxGroupContextProps | null>(null);

/* --- CheckboxGroup  --- */
type CheckboxGroupProps = FormControl<string[]> & {
	children: ReactNode;
	direction?: "horizontal" | "vertical";
} & VisualComponent;

export function CheckboxGroup(props: CheckboxGroupProps): JSX.Element {
	const {
		children,
		name,
		value,
		onChange,
		direction = "vertical",
		id,
		onBlur,
		style,
		className,
	} = props;

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const cbValue = event.target.value;
		const remove = value.includes(cbValue);
		const updated: string[] = remove ? value.filter((x) => x !== cbValue) : [...value, cbValue];
		onChange(updated);
	};

	return (
		<CheckboxGroupContext.Provider
			value={{
				name,
				onBlur,
				onChange: handleChange,
				selectedValues: value,
			}}
		>
			<div
				data-component="checkbox-group"
				id={id}
				role="group"
				aria-label={props["aria-label"]}
				aria-describedby={props["aria-describedby"]}
				aria-labelledby={props["aria-labelledby"]}
				style={style}
				className={cn(className, scss.checkboxGroup, scss[direction])}
			>
				{children}
			</div>
		</CheckboxGroupContext.Provider>
	);
}
/* --- CheckboxGroup Item --- */
type CheckboxGroupItemProps = Omit<CheckboxProps, "checked" | "onChange" | "name">;

function CheckboxGroupItem(props: CheckboxGroupItemProps): JSX.Element {
	const context = React.useContext(CheckboxGroupContext);
	if (context === null)
		throw new Error(
			"The `CheckboxGroup.Checkbox` component must be wrapped by a `CheckboxGroup` component"
		);

	const { value } = props;
	const { selectedValues, ...restContext } = context;
	return <Checkbox {...props} {...restContext} checked={selectedValues.includes(value)} />;
}

CheckboxGroup.Checkbox = CheckboxGroupItem;
