import React, { ReactElement } from "react";
import {
	Button,
	Divider,
	H1,
	Icon,
	IconClose,
	IconElementProps,
	TooltipProps,
} from "@siteimprove/fancylib";
import { useLabTranslations } from "../../../translations/translations";
import * as scss from "./dialog-header.scss";

export type DialogHeaderProps = {
	headerTitle: React.ReactNode;
	headerTitleTooltipText?: TooltipProps["content"];
	headerTitleTooltipPlacement?: TooltipProps["placement"];
	onClose: () => void;
	id?: string;
	headerIcon?: ReactElement<IconElementProps>;
	hideClose?: boolean;
};

export function DialogHeader(props: DialogHeaderProps): JSX.Element {
	const {
		headerIcon,
		headerTitle,
		headerTitleTooltipText,
		headerTitleTooltipPlacement,
		id,
		onClose,
		hideClose,
	} = props;

	const i18n = useLabTranslations();

	return (
		<>
			<header className={scss.header}>
				{headerIcon && (
					<span className={scss.headerIcon}>
						<Icon>{headerIcon}</Icon>
					</span>
				)}
				<H1
					id={id}
					lookalike="h2"
					tooltipText={headerTitleTooltipText}
					tooltipPlacement={headerTitleTooltipPlacement}
				>
					{headerTitle}
				</H1>
				{!hideClose && (
					<Button
						variant="borderless"
						className={scss.close}
						aria-label={`${i18n.closeDialog}${
							typeof headerTitle === "string" ? `: "${headerTitle}"` : ""
						}`}
						onClick={onClose}
					>
						<Icon>
							<IconClose />
						</Icon>
					</Button>
				)}
			</header>
			<Divider />
		</>
	);
}
