import React from "react";
import {
	cn,
	Content,
	Icon,
	IconFinish,
	IconIndeterminate,
	InlineText,
} from "@siteimprove/fancylib";
import { TextHighlight } from "../../text/text-highlight/text-highlight";
import { useDesignToken } from "../../context/theme/theme";
import { OptionItem, SelectProps, SelectStateManager, SelectValue } from "./select";
import {
	BaseSelectOption,
	CompareFunction,
	indexOfOption,
	OptionProps,
	SelectDto,
} from "./select-listbox";
import { BaseOverviewOption } from "./select-overview-list";
import * as listboxScss from "./select-listbox.scss";

// eslint-disable-next-line @typescript-eslint/ban-types
export function useSelectIndeterminateState<T extends readonly object[]>(
	rawItems: SelectDto<T>[],
	itemsState: [SelectDto<T>[], React.Dispatch<React.SetStateAction<SelectDto<T>[]>>],
	levelProperty: keyof SelectDto<T>,
	maxLevel: number,
	onChange: (newSelection: SelectValue<T>, updatedItems: SelectDto<T>[]) => void,
	compareFn: CompareFunction<T>
): Pick<SelectProps<T>, "stateManagerBuilder" | "onChange" | "optionRenderer" | "compareFn"> {
	const [, setLocalItems] = itemsState;
	const { ColorBorderInteractiveSelected } = useDesignToken();

	const updateLevel = (item: SelectDto<T>, newLevel: number): SelectDto<T> => ({
		...item,
		[levelProperty]: newLevel,
	});

	// update the local state and submit the changes
	const localOnChange = (newSelection: SelectValue<T>) => {
		// as we may have subsequent calls to setLocalItems before the onChange on the same render cycle,
		// we need to make sure that the onChange is called with the latest version of the items
		// so we use the callback version of setLocalItems
		setLocalItems((prevItems) => {
			onChange(newSelection, prevItems);
			return prevItems;
		});
	};

	const stateManagerBuilder = (currentSelection: OptionItem<T>[]): SelectStateManager<T> => {
		const toggleOption = (option: OptionItem<T>) => {
			const updatedSelectedOptions = [...currentSelection];
			const targetIndex = indexOfOption(updatedSelectedOptions, option, compareFn);
			let newLevel: number;

			if (targetIndex !== -1) {
				// if it's already selected, set its counter to max if it's not yet
				// otherwise, set its counter to 0 and remove it from the list
				newLevel = option.value[levelProperty] !== maxLevel ? maxLevel : 0;
				newLevel === 0 && updatedSelectedOptions.splice(targetIndex, 1);
			} else {
				// if not selected yet, set its counter to max and append the item
				// to the list
				const sourceItem = rawItems.find((item) => compareFn(item, option.value));
				const previousLevel = sourceItem && (sourceItem[levelProperty] as number);
				newLevel = previousLevel || maxLevel;
				updatedSelectedOptions.push(option);
			}

			setLocalItems((prevItems) =>
				prevItems.map((item) =>
					compareFn(option.value, item) ? updateLevel(item, newLevel) : item
				)
			);

			return updatedSelectedOptions;
		};

		const selectBulk = (options: OptionItem<T>[]) => {
			const updatedSelectedOptions = [...currentSelection];
			options.forEach((option) => {
				const targetIndex = indexOfOption(updatedSelectedOptions, option, compareFn);
				if (targetIndex === -1) {
					updatedSelectedOptions.push(option);
				}
			});

			setLocalItems((prevItems) =>
				prevItems.map((item) =>
					options.findIndex((option) => compareFn(option.value, item)) > -1
						? updateLevel(item, maxLevel)
						: item
				)
			);

			return updatedSelectedOptions;
		};

		const deselectBulk = (options: OptionItem<T>[]) => {
			const updatedSelectedOptions = [...currentSelection];
			options.forEach((option) => {
				const targetIndex = indexOfOption(updatedSelectedOptions, option, compareFn);
				if (targetIndex !== -1) {
					updatedSelectedOptions.splice(targetIndex, 1);
				}
			});

			setLocalItems((prevItems) =>
				prevItems.map((item) =>
					options.findIndex((option) => compareFn(option.value, item)) > -1
						? updateLevel(item, 0)
						: item
				)
			);

			return updatedSelectedOptions;
		};

		const clearSelection = () => {
			setLocalItems((prevItems) => prevItems.map((item) => updateLevel(item, 0)));
			return [];
		};

		const isEligibleToSelectAll = (option: OptionItem<T>) =>
			(option.value[levelProperty] as number) !== maxLevel;

		const onCancel = () => setLocalItems(rawItems);

		const stateCompareFn = (set1: OptionItem<T>[], set2: OptionItem<T>[]) => {
			return (
				set1.length == set2.length &&
				set1.every((a) =>
					set2.some(
						(b) => compareFn(a.value, b.value) && a.value[levelProperty] === b.value[levelProperty]
					)
				)
			);
		};

		return {
			clearSelection,
			deselectBulk,
			isEligibleToSelectAll,
			onCancel,
			selectBulk,
			stateCompareFn,
			toggleOption,
		};
	};

	const optionRenderer = (
		props: OptionProps<SelectDto<T>[]> & React.RefAttributes<HTMLDivElement>
	): JSX.Element => {
		const { item, isSelected, needle, caseSensitive } = props;
		const isFullySelected = item.value[levelProperty] === maxLevel;
		return (
			<BaseSelectOption
				{...props}
				key={item.title}
				aria-checked={isSelected ? (isFullySelected ? "true" : "mixed") : "false"}
			>
				<Content
					padding="none"
					flexDirection="row"
					justifyContent="space-between"
					alignItems="center"
					style={{ width: "100%" }}
				>
					<Content padding="none" flexDirection="row" alignItems="center" gap="xxSmall">
						<InlineText
							tone="neutralDark"
							lineHeight="multi-line"
							emphasis={isSelected ? "medium" : "normal"}
						>
							<TextHighlight value={item.title} needle={needle} caseSensitive={caseSensitive} />
						</InlineText>
					</Content>
					{isSelected &&
						(isFullySelected ? (
							<Icon size="medium" fill={ColorBorderInteractiveSelected}>
								<IconFinish />
							</Icon>
						) : (
							<Icon size="medium" fill={ColorBorderInteractiveSelected}>
								<IconIndeterminate />
							</Icon>
						))}
				</Content>
				<InlineText size="xSmall" tone="subtle" lineHeight="multi-line">
					{item.description}
				</InlineText>
			</BaseSelectOption>
		);
	};

	return { stateManagerBuilder, onChange: localOnChange, optionRenderer, compareFn };
}

export function useOptionTitleRenderer<T>(
	renderTitle: (
		title: string,
		options: {
			disabled: boolean | undefined;
			isSelected: boolean;
			isOverview: boolean;
			needle: string | null;
			caseSensitive?: boolean;
		},
		item: OptionItem<T>
	) => JSX.Element
): Pick<SelectProps<T>, "optionRenderer" | "overviewOptionRenderer"> {
	const { ColorBorderInteractiveSelected } = useDesignToken();

	const optionRenderer: SelectProps<T>["optionRenderer"] = (props) => {
		const { caseSensitive, disabled, item, isSelected, multiSelectable, needle } = props;
		const { title, description, icon } = item;
		return (
			<BaseSelectOption {...props} key={item.title}>
				<div className={cn(listboxScss.optionMainContainer)}>
					<div className={cn(listboxScss.optionTitleContainer)}>
						{icon}
						{renderTitle(
							title,
							{
								needle,
								caseSensitive,
								disabled,
								isSelected,
								isOverview: false,
							},
							item
						)}
					</div>
					{multiSelectable && isSelected && (
						<Icon size="medium" fill={ColorBorderInteractiveSelected}>
							<IconFinish />
						</Icon>
					)}
				</div>
				{description && (
					<InlineText size="xSmall" tone="subtle" lineHeight="multi-line">
						{description}
					</InlineText>
				)}
			</BaseSelectOption>
		);
	};

	const overviewOptionRenderer: SelectProps<T>["overviewOptionRenderer"] = (props) => {
		const { item, disabled } = props;
		const { title, description, icon } = item;

		return (
			<BaseOverviewOption {...props} key={item.title}>
				<div className={cn(listboxScss.optionTitleContainer)} aria-hidden="true">
					{icon}
					{renderTitle(
						title,
						{
							disabled,
							needle: null,
							isSelected: false,
							isOverview: true,
						},
						item
					)}
				</div>
				{description && (
					<InlineText size="xSmall" tone="subtle" lineHeight="multi-line">
						{description}
					</InlineText>
				)}
			</BaseOverviewOption>
		);
	};

	return { optionRenderer, overviewOptionRenderer };
}
