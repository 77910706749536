import * as React from "react";
import { DataObserveKey, Paragraph, VisualComponent } from "@siteimprove/fancylib";

type BigSmallProps = {
	/** Content to be displayed as big */
	big: React.ReactNode;
	/** Content to be displayed as small */
	small: React.ReactNode;
} & DataObserveKey &
	VisualComponent;

export function BigSmall(props: BigSmallProps): JSX.Element {
	const { big, small, className, style } = props;
	return (
		<div
			data-observe-key={props["data-observe-key"]}
			data-component="big-small"
			className={className}
			style={style}
		>
			<Paragraph>{big}</Paragraph>
			<Paragraph size="xSmall">{small}</Paragraph>
		</div>
	);
}
