import React from "react";

export const FANCY_DROPZONE_CLASS = "fancy-dropzone";

export function DialogDropzone(): JSX.Element {
	return <div className={`fancy-root ${FANCY_DROPZONE_CLASS}`} />;
}

export function findOrCreateDialogDropzone(): Element | null {
	if (typeof document === "undefined") {
		return null;
	}
	const dropzones = document.getElementsByClassName(FANCY_DROPZONE_CLASS);
	if (dropzones.length === 0) {
		const dropzone = document.createElement("div");
		dropzone.classList.add(FANCY_DROPZONE_CLASS);
		dropzone.classList.add("fancy-root");
		document.body.appendChild(dropzone);
		return dropzone;
	}
	return dropzones[0];
}
