import React, { useEffect, useRef, useState } from "react";
import { Link as GatsbyLink } from "gatsby";
import {
	H4,
	Button,
	Icon,
	IconLink,
	Paragraph,
	TextContainer,
	Ul,
	InlineText,
} from "@siteimprove/fancylib";
import {
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Knobs,
	DocsTable,
	Code,
} from "../../../../../src/docs";
import { CodeBlock } from "../../../../../src/docs/docs-code";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { Checkbox } from "../checkbox/checkbox";
import { FormElementWrapper } from "../form-element-wrapper/form-element-wrapper";
import { BigSmall } from "../../text/big-small/big-small";
import { Modal } from "../../overlay/modal/modal";
import { TextHighlight } from "../../text/text-highlight/text-highlight";
import { Pill } from "../../actions-and-controls/pill/pill";
import { Select, OptionItem, SelectProps, BaseSelectOption, BaseOverviewOption } from "./select";
import { useOptionTitleRenderer, useSelectIndeterminateState } from "./select-utils";

export const Meta: DocPageMeta = {
	category: "Forms and input",
	title: "Select",
	notepad: "https://hackmd.io/f-Pf5xijQSeS1bLoa0iNuA",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Select"
			subTitle="Select allows the user to choose one or more items from a list. The selected item can represent a value in a form or be used to filter or sort existing content. "
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={Select} />
				<Header.H2>Similar components with different purposes</Header.H2>
				<Paragraph>
					To clarify the differences between <Code>Select</Code> and <Code>ActionMenu</Code>, a
					short description is provided.
				</Paragraph>
				<DocsTable>
					<thead>
						<tr>
							<th>Component name</th>
							<th>Usage</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Select</td>
							<td>Choose one or more values from a list.</td>
						</tr>
						<tr>
							<td>Action Menu</td>
							<td>A list of individual actions that the user can perform and links.</td>
						</tr>
					</tbody>
				</DocsTable>
				<Header.H2>Examples</Header.H2>
				<Header.H3>Single Select</Header.H3>
				<Paragraph>
					A single <Code>Select</Code> allows the user to choose only one item from a list of
					mutually exclusive options. If you have more than 7 items, you should use a{" "}
					<Code>Select</Code>. If you have 3-6 items, you can use either <Code>Radios</Code> or a{" "}
					<Code>Select</Code>.
				</Paragraph>
				<Paragraph>
					A default <Code>Select</Code> consists of a button, a down-arrow icon, a list box, and a
					clear all button that is only displayed when an option is selected.
				</Paragraph>
				<Ul
					items={[
						<>
							<b>Label</b>: when using a standalone <Code>Select</Code>, you should always have a
							label. A label text should inform the user what to select from a list of options. Use{" "}
							<GatsbyLink to="/lab/components/Forms and input/Form element wrapper">
								Form Element Wrapper
							</GatsbyLink>{" "}
							around a <Code>Select</Code> to get the proper label.
						</>,
						<>
							<b>Button</b>: the user can click the button to show a list of items. After selection,
							the item is displayed in the button.
						</>,
						<>
							<b>Down-arrow icon</b>: indicates if the list box is shown or not.
						</>,
						<>
							<b>List box</b>: contains a list of selectable items that can be grouped into
							categories and contain an icon, description, or both (see{" "}
							<a href="#item-variants"> Items Variants</a> below). If you select an item or click
							outside the list box, it will be hidden.
						</>,
						<>
							<b>Clear all button</b>: the user can remove selected items by clicking the X icon.{" "}
						</>,
					]}
				/>
				<Example fn={BasicUsage} />
			</TextContainer>
			<TextContainer article>
				<Header.H3>Multi Select</Header.H3>
				<Paragraph>
					A multi <Code>Select</Code> allows the user to select or deselect one or more items.
				</Paragraph>
				<Paragraph>
					A multi <Code>Select</Code> includes all of the elements from a single <Code>Select</Code>{" "}
					with the following additions:
				</Paragraph>
				<Ul
					items={[
						<>
							<b>Badge</b>: shows the number of selected items.
						</>,
						<>
							<b>Checkmark icon</b>: indicates which item is currently selected in the list.
						</>,
						<>
							<b>Footer</b>: the user can confirm the selection or click the “Cancel” button to
							reset the selection.
						</>,
					]}
				/>

				<Paragraph>Use array-like values to enable the multi select mode.</Paragraph>
				<Example fn={MultiSelectExample} />
			</TextContainer>
			<TextContainer article>
				<Header.H3>Searchable</Header.H3>
				<Paragraph>
					The search field allows the user to type a keyword to search for an option. As the user
					types, the <Code>searchable</Code> <Code>Select</Code> filters the results. This helps the
					user find something quickly in a large list of options. For example, a{" "}
					<Code>searchable</Code> <Code>Select</Code> is most often used for filtering a list of
					countries, as it is extensive, but also easy to answer.{" "}
				</Paragraph>
				<Paragraph>
					Use a <Code>searchable</Code> <Code>Select</Code> when
				</Paragraph>
				<Ul
					items={[
						<>the user needs to filter a large list of items.</>,
						<>the user can quickly find a known option.</>,
					]}
				/>
				<H4>For developers</H4>
				<Paragraph>
					You can use the <Code>searchable</Code> to control whether or not to display a search bar
					above the list box. If set to <Code>auto</Code> (the default), the search bar will be
					visible only when there are more than 7 items. If set to <Code>always</Code>, it will be
					visible no matter the total amount of options. And, if set to <Code>never</Code>, it will
					not be displayed.
				</Paragraph>
				<Paragraph>
					By default, the search bar will search for the <Code>title</Code> property of the options.
					However, you can specify custom searchable content to search for by using the{" "}
					<Code>searchableContent</Code> prop. This prop should be a function that takes an option
					as an argument and returns a string that will be used to match the search query. You can
					see it in action in the <a href="#groups">Groups</a> example, where the searchable content
					takes both the option title and description.
				</Paragraph>
				<Paragraph>
					For more complex use cases, you can use the <Code>onSearch</Code> property which is a
					function that takes a query string as an argument. In this case, the developer is
					responsible for using this query string to fetch new items and update the options list.
					Also, the developer must manage when to include the selected options in the list. You can
					see it in action in the <a href="#async">Async</a> examples.
				</Paragraph>
				<Example fn={SearchableExample} />
			</TextContainer>
			<TextContainer article>
				<Header.H3>Common Use Cases</Header.H3>
				<Header.H4>With default option</Header.H4>
				<Paragraph>
					Whenever possible, give the user the optimal option by default. This will help you avoid
					cases where the alternative options can often lead to errors or be confusing for
					inexperienced users.
				</Paragraph>
				<Paragraph>
					You can specify an option to be selected by default using the <Code>defaultOption</Code>{" "}
					property. If this property is not provided or is undefined, the first option will be
					selected by default in single select mode.
				</Paragraph>
				<Example fn={WithDefaultOptionExample} />
				<Header.H4>Without default option</Header.H4>
				<Paragraph>
					You can use the <Code>noDefaultOption</Code> property to not select an option by default.
					In this case, when the selection is empty, the placeholder will be displayed in the{" "}
					<Code>Select</Code>
					button content.
				</Paragraph>
				<Paragraph>
					For the <Code>Select</Code> without default option, a label should be provided to the
					user. A label should clearly describe the purpose of the selection.
				</Paragraph>
				<H4>
					<b>Placeholder text</b>
				</H4>
				<Paragraph>
					A placeholder text can serve as a hint, description, or an example of the information
					required for a particular field.{" "}
				</Paragraph>
				<Paragraph>
					However, placeholder text <b>should not</b> be used as a label, since it disappears when
					an item is selected. In most cases, the user will forget what information belongs in a
					field. As a result, the user with visual and cognitive impairments are faced with an
					additional burden.
				</Paragraph>
				<Example fn={WithoutDefaultOptionExample} />
				<H4>With custom keys</H4>
				<Paragraph>
					In simple terms, the select list box functions as an array of options. As explained in the
					React{" "}
					<a href="https://react.dev/learn/rendering-lists#why-does-react-need-keys">
						documentation
					</a>
					, each item in the list requires a <Code>key</Code> prop, which must be unique. Without
					this key, the component defaults to using the option's title, which can cause issues if
					titles aren't unique. If ensuring title uniqueness isn't feasible, it's crucial to assign
					a unique key to each option, as shown in the example below.
				</Paragraph>
				<Example fn={WithoutCustomKeysExample} />
				<Header.H4>With TypeScript union</Header.H4>
				<Example fn={TypeScriptUnionExample} />
			</TextContainer>
			<TextContainer article>
				<Header.H3>Bulk Actions</Header.H3>
				<Paragraph>
					When many options need to be selected, it is recommended to include "Select all" and
					"Deselect all" buttons upfront to provide flexibility to the user and speed up the bulk
					selection process.
				</Paragraph>
				<Paragraph>The following examples show the number of selected/deselected items:</Paragraph>
				<Ul
					items={[
						<>
							<b>Select all (2)</b>: indicates that two options in the list were not selected. The
							user can select the remaining two selectable items at once.{" "}
						</>,
						<>
							<b>Deselect all (3)</b>: indicates that three options are already selected in the
							list. The user can deselect all three selected options at once.
						</>,
					]}
				/>
				<Paragraph>
					Note the "Select all" button is disabled once all items are selected. The "Deselect all"
					button is only available if the user has at least one item selected. Otherwise, the button
					is disabled.
				</Paragraph>
				<Example fn={BulkActionsExample} />
			</TextContainer>
			<TextContainer article>
				<Header.H3>Max Number of Items</Header.H3>
				<Paragraph>
					A badge and hint text indicate the number of items that can be selected in advance.
					Providing helpful constraints avoids user errors. For example, the user can immediately
					select the correct number of items instead of receiving an error message after submitting
					the form.
				</Paragraph>
				<Paragraph>
					Note that after selecting the maximum number of items, the other items are disabled. The
					user must deselect one of the selected items to be able to select another item.
				</Paragraph>
				<Example fn={MultiSelectExampleWithMaxNumberOfItems} />
			</TextContainer>
			<TextContainer article>
				<Header.H3>Creatable</Header.H3>
				<Paragraph>
					Use creatable <Code>Select</Code> to allow the user to add a new item to the list for
					selection. You must also provide the <Code>onCreate</Code> function that will be called
					when the user clicks the create button. This function takes the value present on the
					search field as an argument and must return a new option (type of <Code>OptionItem</Code>)
					to be added to the list. The new option will be selected automatically after creating it.
					You can also change the label of the create button by using the
					<Code>createButtonLabel</Code> prop.
				</Paragraph>
				<Example fn={CreatableExample} />
				<Header.H4>With validation</Header.H4>
				<Paragraph>
					By default, the list box will be hidden after creating a new option in single select mode
					and kept open in multi select mode. However, you can control this behavior by returning a
					boolean in the <Code>onCreate</Code> function, where <Code>false</Code> means closing the
					list box. This is useful when you want to validate the new option before adding it to the
					list. Here is an example of how to do it:
				</Paragraph>
				<Example fn={CreatableWithValidationExample} />
			</TextContainer>
			<TextContainer article>
				<Header.H3>Item variants</Header.H3>
				<Paragraph>
					A selectable item can be provided with an icon and description to give additional context
					or instructions about what a user should select. Keep items concise and consistent.
				</Paragraph>
				<Ul
					items={[
						<>
							<b>Title only</b>: the text describes the selectable item. Long items where the text
							is divided into multiple lines are not recommended.
						</>,
						<>
							<b>Title and description</b>: provide additional context for the item, no longer than
							one line.
						</>,
						<>
							<b>Title, description, and icon</b>: helps the user immediately understand the item.
							An icon must convey the meaning of the item and should not be used for decorative
							purposes.
						</>,
					]}
				/>
				<H4>State variations</H4>
				<Paragraph>Each item has the following states:</Paragraph>
				<Ul
					items={[
						<>
							<b>Default</b>: the default state is the start or end state, indicating whether the
							<Code>Select</Code> button is empty or filled.{" "}
						</>,
						<>
							<b>Hover</b>: when a user hovers over an item, a highlight (light gray background)
							appears indicating that the item is selectable.
						</>,
						<>
							<b>Focus</b>: a blue outline appears around the text area when the user tabs or uses
							the keyboard to navigate through the items.
						</>,
						<>
							<b>Active/Pressed</b>: a light blue background appears while the item is clicked.
						</>,
						<>
							<b>Selected</b>: the left border and checkmark icon indicate which item in the list is
							currently selected.
						</>,
						<>
							<b>Disabled</b>: the disabled option should be used infrequently. It indicates that
							the option is present, but is not currently available to the user.
						</>,
					]}
				/>
				<Example fn={VariantsExample} />
			</TextContainer>
			<TextContainer article>
				<Header.H3>Groups</Header.H3>
				<Paragraph>
					Group related items in the list to give the user an overview of the list. Empathize with
					the user. Seeing a list of ungrouped options, can make it hard for the user to find the
					option they are looking for. Avoid having only one group in a list box.
				</Paragraph>
				<Example fn={GroupsExample} />
			</TextContainer>
			<TextContainer article>
				<Header.H3>Async</Header.H3>
				<Paragraph>
					Use async <Code>Select</Code> to load asynchronous data from a remote source, either while
					loading or each time the value changes.
				</Paragraph>
				<Paragraph>For the examples below, consider the following API:</Paragraph>
				<CodeBlock language="tsx">
					{`type Fruit = {
	id: number;
	name: string;
};

type API = {
	fruits: Fruit[];
};

const api: API = {
	fruits: [
		{ id: 0, name: "Apple" },
		{ id: 1, name: "Banana" },
		//...
		{ id: 14, name: "Watermelon" },
	],
};`}
				</CodeBlock>
				<Paragraph>
					The entire options list can be fetched asynchronously and stored in a state. You can use
					the <Code>loading</Code> property to display a spinner in the list box indicating that
					options are being loaded.
				</Paragraph>
				<Example fn={AsyncItemsExample} />
				<Paragraph>
					You can also use the <Code>onSearch</Code> property to fetch new options when the user
					types in the search bar. You must update the options list with the query results. Be sure
					to include the selected options in the options list when the search query is empty,
					otherwise the selection will not be displayed.
				</Paragraph>
				<Example fn={AsyncExample} />
			</TextContainer>
			<TextContainer article>
				<Header.H3>Select Overview</Header.H3>
				<Paragraph>
					Use the <Code>showSelectionOverview</Code> property to show a list of selected items just
					below the <Code>Select</Code> button. The user can easily see all selected items in the
					list box. In addition, the user can easily deselect items directly from the Selection
					Overview by clicking the X icon.
				</Paragraph>
				<Example fn={SelectOverviewExample} />
			</TextContainer>
			<TextContainer article>
				<Header.H3>Invalid</Header.H3>
				<Paragraph>
					A <Code>Select</Code> can be marked as having an error to indicate that an entered value
					is invalid. Use the error message to inform the user what has happened, and then provide
					guidance on next steps or possible solutions. The best practice is to verify the user's
					data before they have filled in all the fields of a form.
				</Paragraph>
				<Paragraph>
					The error message can also indicate that the input is empty, e.g. "Select a country". This
					can happen if a user clicks the Submit button before they have fully entered the value.
					However, if we have correctly marked required or optional fields, the user knows which
					fields are required (see <a href="#best-practices">Best Practices</a>). Follow the writing
					guideline for{" "}
					<a href="https://fancy.siteimprove.com/Writing/tone-of-voice#issues-and-issue-descriptions">
						Issues and issue descriptions
					</a>
					.
				</Paragraph>
				<Example fn={InvalidExample} />
			</TextContainer>
			<TextContainer article>
				<Header.H3>Disabled</Header.H3>
				<Paragraph>
					The disabled state indicates that the <Code>Select</Code> exists but is not available
					under some circumstances. This can be used to maintain continuity of the layout and to
					communicate that it may be available later. If possible, provide a hint text or a visual
					clue to explain why the <Code>Select</Code> is disabled to avoid user confusion.
				</Paragraph>
				<Example fn={DisabledExample} />
			</TextContainer>
			<TextContainer article>
				<Header.H3>Custom option element</Header.H3>
				<Paragraph>
					If the item variants do not meet your requirements, you can use custom option elements.
					Consistency is critical when using custom option elements. Make sure they have a
					consistent pattern and contain only the necessary information. This example shows the
					consistency of labels with basic checkboxes. Note that you can provide custom renderers
					both for the options in the list box and for the selected items in the selection overview.
				</Paragraph>
				<Example fn={CustomOptionElementExample} />
				<Header.H4>Custom option title</Header.H4>
				<Paragraph>
					You can also use a simpler way to create custom option elements. This example shows the
					use of the <Code>useOptionTitleRenderer</Code> hook to customize the way the option title
					is rendered.
				</Paragraph>
				<Example fn={CustomOptionTitleExample} />
			</TextContainer>
			<TextContainer article>
				<Header.H3>Custom button element</Header.H3>
				<Paragraph>
					If the default button does not meet your requirements, you can use a custom button
					element. Consistency is critical when using a custom button element. Make sure it contain
					only the necessary information. This example shows a label with description.
				</Paragraph>
				<Example fn={CustomButtonElementExample} />
			</TextContainer>
			<TextContainer article>
				<Header.H3>Custom size</Header.H3>
				<H4>Choose an appropriate width</H4>
				<Paragraph>
					The items should not be wider than the text. The text may be truncated if it's wider than
					the item, making it hard for users to read.
				</Paragraph>
				<Paragraph>
					Custom size support provides more flexibility in structuring layouts and should be used to
					create a hierarchy of importance within the page. Use a consistent <Code>width</Code> when
					used alongside other{" "}
					<GatsbyLink to="/lab/components/Forms and input/Form element wrapper">
						Form Element Wrapper
					</GatsbyLink>{" "}
					on the same page. Use the <Code>fullWidth</Code> size sparingly.
				</Paragraph>
				<Paragraph>To control the width of the button, you can use</Paragraph>
				<Ul
					items={[
						<>
							the <Code>width</Code> property to adjust the width of the button.
						</>,
						<>
							the <Code>fullWidth</Code> property to fully extend the width of the button.
						</>,
					]}
				/>
				<Paragraph>
					By default, the list box has a minimum width of 15 rem, and a maximum width of 37.5 rem.
					If you want to control the width of the list box, it is recommend to use
				</Paragraph>
				<Ul
					items={[
						<>
							the <Code>listboxWidth</Code> property to adjust the width of the list box.
						</>,
						<>
							the <Code>noListboxMinWidth</Code> property to disable the default minimum list box
							width.
						</>,
						<>
							the <Code>noListboxMaxWidth</Code> property to disable the default maximum list box
							width.
						</>,
					]}
				/>
				<H4>Adjust height for list box</H4>
				<Paragraph>
					By default, the list box has a maximum height of 20.25 rem, which is about 7.5 items in
					the list. This means that 7 items will completely fit in the list box and the 8th item
					will be cut in half, which alongside the scrollbar, visually indicates that there are more
					items in the list box.
				</Paragraph>
				<Paragraph>
					When using custom option elements, the number of items displayed can vary, but make sure
					the user can clearly see these indicators of list box continuity, especially in a{" "}
					<Code>Modal</Code>.
				</Paragraph>
				<Example fn={CustomSizeExample} />
			</TextContainer>
			<TextContainer article>
				<Header.H3>List box placement</Header.H3>
				<Paragraph>
					Sometimes, the list box may not be visible because it is placed outside the viewport. You
					can use the <Code>placement</Code> property to specify where the list box should be
					placed. The default value is <Code>bottom-start</Code>.
				</Paragraph>
				<Example fn={PlacementExample} />
			</TextContainer>
			<TextContainer article>
				<Header.H3>Usage with data-observe-keys</Header.H3>
				<Paragraph>
					Use <Code>data-observe-key</Code> on the main component and/or individual items to create
					identifiers, which are useful for tracking user interactivity, for example. Inner buttons
					and inputs, such as confirm, cancel, clear, bulk actions, and search bar, will be assigned
					with the same <Code>data-observe-key</Code> plus a discriminator. For instance, when using{" "}
					<Code>{'<Select data-observe-key="foo" />'}</Code>, the clear button will be assigned with{" "}
					<Code>foo-ClearButton</Code>. See below a complete example and note this pattern in
					action.
				</Paragraph>
				<Example fn={UsageWithDataObserveKeys} />
			</TextContainer>
			<TextContainer article>
				<Header.H3>Indeterminate State</Header.H3>
				<Paragraph>
					The <Code>useSelectIndeterminateState</Code> hook enhances the Select component by
					providing a subset of props with a custom state manager. This enables the creation of an
					intermediate selection state. Let's take a look at an example that demonstrates how to
					utilize this hook to introduce a new type of selection: partial selection. In this
					scenario, each option represents a tag, and each tag can be active on zero or multiple
					websites. A tag is considered fully selected if it is active on all websites. If it is
					active on some, but not all websites, it is classified as partially selected. Finally, if
					a tag is not active on any website, it is considered unselected.
				</Paragraph>
				<Example fn={IndeterminateStateExample} />
			</TextContainer>
			<TextContainer article>
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={Select as React.FC<SelectProps<string>>}
					initialProps={({ setState }) => ({
						onChange: (item: string | undefined) => setState({ value: item }),
						value: "apple",
						items: [
							{ title: "Apple", value: "apple" },
							{ title: "Banana", value: "banana" },
							{ title: "Blueberry", value: "blueberry" },
							{ title: "Cherry", value: "cherry" },
						],
					})}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>Select</Code> when:
					</Paragraph>
					<Paragraph>
						the user can select one or multiple items from a list of options, preferably between 7
						to 15 items.
					</Paragraph>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						A <Code>Select</Code> is typically used in the following places:
					</Paragraph>
					<Ul
						items={[
							<>
								<H4>Filter</H4>A <Code>Select</Code> is often used in tables to filter data on a
								page. The user can select one or more refinements to narrow the amount of data
								displayed. An example can be found in{" "}
								<a href="https://my2.siteimprove.com/Auth/Direct?personId=128151299&accountId=30156&back=%2FSEOv2%2F446001%2FKeywordMonitoring%2FIndex%3Flang%3Den-US">
									{"Main menu > SEO > Research > Keyword Monitoring"}
								</a>
								.
							</>,
							<>
								<H4>Form</H4>
								<Ul
									items={[
										<>
											Labels are essential to the usability of forms. It is recommended to use the{" "}
											<GatsbyLink to="/lab/components/Forms and input/Form element wrapper">
												Form Element Wrapper
											</GatsbyLink>{" "}
											.
										</>,
										<>
											To avoid overloading the user with too much information, a single{" "}
											<Code>Select</Code> is often used to present a list of items.
										</>,
										<>
											In a form, a <Code>Select</Code> with a default option often emphasizes that
											the selected item is more important than viewing other available items.
										</>,
										<>
											Use an{" "}
											<GatsbyLink to="/lab/components/Forms and input/Input Field">
												Input Field
											</GatsbyLink>{" "}
											if screen space is limited and the user knows the exact value.
										</>,
										<>
											Use{" "}
											<GatsbyLink to="/lab/components/Forms and input/Radios">Radios</GatsbyLink> or{" "}
											<GatsbyLink to="/lab/components/Forms and input/Checkbox">
												Checkbox
											</GatsbyLink>{" "}
											for small sets of items (7 or less).
										</>,
									]}
								/>
								An example can be found in{" "}
								<a href="https://my2.siteimprove.com/Auth/Direct?personId=128151299&accountId=30156&back=%2FSEOv2%2F446001%2FActivityPlans%2FCreateActivityPlan%3Flang%3Den-US">
									{"Main menu > SEO > Activity Plans > Create Activity Plan"}
								</a>
								.
							</>,
							<>
								<H4>Modal</H4>
								In a <Code>Modal</Code>, a <Code>Select</Code> allows the user to efficiently
								perform a task with a default option. Avoid overwhelming the user with too many
								items, as this can slow down their workflow, if possible. An example can be found in{" "}
								<a href="https://my2.siteimprove.com/Auth/Direct?personId=128151299&accountId=30156&back=%2FSEOv2%2F446001%2FContentOptimization%2FIndex%3Flang%3Den-US">
									{"Main menu > SEO > Optimize > Content Optimization > Add keywords"}
								</a>
								.
							</>,
							<>
								<H4>Builder</H4>Multiple <Code>Select</Code> are often used to present a set of
								conditions that builds upon each other within a Builder. The user can add, edit, or
								delete each condition and possibly rearrange the order. Examples can be found in :{" "}
								<a href="https://my2.siteimprove.com/Auth/Direct?personId=128151299&accountId=30156&back=%2FKeyPages%2F17815375225%2FKeyPages%2FCriterionSetBuilder%3Flang%3Den-US">
									{"Main menu > Key pages > Pages > Create criteria set > Criteria builder"}
								</a>
								.
							</>,
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								Keep the label and description concise. Multi-line text wrapping is discouraged.
								Consider revising the text or using an alternative UI component that gives your
								content more space.
							</>,
							<>
								The <Code>Select</Code> component should be vertically aligned with the grid and
								other{" "}
								<GatsbyLink to="/lab/components/Forms and input/Form element wrapper">
									Form Element Wrapper
								</GatsbyLink>{" "}
								components on a page.
							</>,
						]}
					/>
					<Header.H4>Required or optional</Header.H4>
					<Ul
						items={[
							<>
								Currently, all fields in a form are assumed to be mandatory, and optional fields are
								marked. Therefore, it is recommended to provide a hint text that either states "All
								fields are required" or "All fields are required unless marked as optional".{" "}
							</>,
							<>Optional fields are marked with the text "(optional)" at the end of the label.</>,
							<>Asterisks (*) should never be used to indicate that a field is optional.</>,
						]}
					/>

					<Header.H4>Order of options</Header.H4>
					<Ul
						items={[
							<>The order of the list should be based on frequency of use, if possible.</>,
							<>
								In forms, a different order, such as alphabetical, may be more appropriate, such as
								a list of country names.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							<>there are only 1-2 items. Choose a component that meets your needs.</>,
							<>
								the user is very familiar with the data, e.g. the day, month, or year of birth.
								Searching for these options in a long list box is tedious and can add to the user's
								workload. Instead, use the{" "}
								<GatsbyLink to="/lab/components/Forms and input/Input Field">
									Input Field
								</GatsbyLink>
								.
							</>,
							<>
								the user can trigger an action based on the selected option, such as displaying a
								modal or dynamic controls. Instead, use the{" "}
								<GatsbyLink to="/lab/components/Actions and controls/Action menu">
									Action Menu
								</GatsbyLink>
								.
							</>,
							<>
								the user can select only one option from a list of fewer than 7 mutually exclusive
								options. In this case, use a{" "}
								<GatsbyLink to="/lab/components/Forms and input/Radios">Radios</GatsbyLink> instead.
							</>,
							<>
								displaying information that is too complex. Keep the selection of items as simple as
								possible.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<H4>For designers</H4>
					<Ul
						items={[
							<>
								Do not rely on placeholder text to explain input fields - combine a concise label
								with supportive help text.
							</>,
							<>
								Label the input field with the information it should contain. Labels should be
								positioned outside the field so that they are always visible.
							</>,
							<>
								A long list of options should be used with caution. If there are many choices, the
								user must scroll, making it impossible to see all of them at once. Longer and
								narrower lists require more time for the user to move the mouse pointer between
								them.
							</>,
							<>
								The user should not be navigated to another page, a modal, or changed to a different
								view without first being asked for confirmation.
							</>,
						]}
					/>
					<H4>For developers</H4>
					<Paragraph>
						A <Code>Select</Code> must convey both its visible label and its selected item by its
						"Accessible Name". The "Accessible Name" is usually created with <Code>aria-label</Code>{" "}
						or <Code>aria-labelledby</Code>. You can use the Accessibility panel in Chrome's
						DevTools and Safari’s Audit to inspect the "Accessible Name" of your <Code>Select</Code>
						.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							<>
								If there is no logical default option, use a {"{verb + noun}"} combination that
								clearly describes the selection purpose as the default text for the placeholder,
								such as "Select tag(s)".
							</>,
							<>Keep labels short and concise, aim for 1-3 words.</>,
							<>Labels, placeholder text, and items should be in sentence case.</>,
							<>Do not include punctuation after a label.</>,
							<>Avoid unnecessary words and articles such as "the", "an", or "a".</>,
						]}
					/>
				</InlineMessage>
			</TextContainer>
			<TextContainer article>
				<Header.H2>Notable Changes</Header.H2>
				<Header.H3>Version 52.0.0</Header.H3>
				<Paragraph>
					The <Code>multiple</Code> prop and both <Code>SingleSelectProps</Code> and{" "}
					<Code>MultiSelectProps</Code> types were removed in favor of a single{" "}
					<Code>SelectProps</Code> type. With this change, the type inference logic has been
					improved to determine the mode of the <Code>Select</Code> based on the <Code>value</Code>{" "}
					prop's type. If the <Code>value</Code>
					prop is an array, then the multi select mode is enabled, otherwise, the single select mode
					takes place. These changes streamline the codebase and make it easier to use the{" "}
					<Code>Select</Code> component.
				</Paragraph>
				<Paragraph>
					Therefore, to adapt to these changes, it is necessary to remove the <Code>multiple</Code>{" "}
					prop and explicit literals in the React code and use the new common{" "}
					<Code>SelectProps</Code> type.
				</Paragraph>
				<Header.H4 id="before-52.0.0">Before</Header.H4>
				<CodeBlock language="tsx">{`
const [value, setValue] = useState<string[]>([]);

<Select<string>
  multiple
  value={value}
  onChange={setValue}
/>
`}</CodeBlock>
				<Header.H4 id="after-52.0.0">After</Header.H4>
				<CodeBlock language="tsx">{`
const [value, setValue] = useState<string[]>([]);

<Select
  value={value}
  onChange={setValue}
/>
`}</CodeBlock>
				<Header.H3>Version 60.0.0</Header.H3>
				<Paragraph>
					The <Code>onCreate</Code> function must now return an <Code>OptionItem</Code> or a{" "}
					<Code>boolean</Code>. Also, developers don't need to include the newly created option in
					the list of selected options, as the component will automatically select it.
				</Paragraph>
				<Header.H4 id="before-60.0.0">Before</Header.H4>
				<CodeBlock language="tsx">{`
const [items, setItems] = useState<string[]>(["Foo", "Bar"]);
const [selectedOption, setSelectedOption] = useState<string>();
const itemize = (value: string): OptionItem<string> => ({ title: value, value });

<Select
	items={items.map(itemize)}
	value={selectedOption}
	onChange={setSelectedOption}
	creatable
	onCreate={(newValue) => {
		setItems([...items, newValue]);
		setSelectedOption(newValue);
	}}
/>
`}</CodeBlock>
				<Header.H4 id="after-60.0.0">After</Header.H4>
				<CodeBlock language="tsx">{`
const [items, setItems] = useState<string[]>(["Foo", "Bar"]);
const [selectedOption, setSelectedOption] = useState<string>();
const itemize = (value: string): OptionItem<string> => ({ title: value, value });

<Select
	items={items.map(itemize)}
	value={selectedOption}
	onChange={setSelectedOption}
	creatable
	onCreate={(newValue) => {
		setItems([...items, newValue]);
		return itemize(newValue);
	}}
/>
`}</CodeBlock>
			</TextContainer>
		</ContentSection>
	</>
);

const BasicUsage = () => {
	const [selectedOption, setSelectedOption] = useState<string | undefined>();
	return (
		<Select
			aria-label="List of fruits"
			items={[
				{ title: "Apple", value: "apple" },
				{ title: "Banana", value: "banana" },
				{ title: "Blueberry", value: "blueberry" },
				{ title: "Cherry", value: "cherry" },
			]}
			value={selectedOption}
			onChange={setSelectedOption}
		/>
	);
};

const MultiSelectExample = () => {
	const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
	return (
		<Select
			aria-label="List of fruits"
			items={[
				{ title: "Apple", value: "apple" },
				{ title: "Banana", value: "banana" },
				{ title: "Blueberry", value: "blueberry" },
				{ title: "Cherry", value: "cherry" },
			]}
			value={selectedOptions}
			onChange={setSelectedOptions}
		/>
	);
};

/**
 * @click button
 * */
const SearchableExample = () => {
	const [selectedOption, setSelectedOption] = useState<string | undefined>();
	return (
		<Select
			aria-label="List of fruits"
			items={[
				{ title: "Apple", value: "apple" },
				{ title: "Banana", value: "banana" },
				{ title: "Blueberry", value: "blueberry" },
				{ title: "Cherry", value: "cherry" },
			]}
			value={selectedOption}
			onChange={setSelectedOption}
			searchable="always"
		/>
	);
};

const WithDefaultOptionExample = () => {
	const [selectedOption, setSelectedOption] = useState<string | undefined>();
	return (
		<Select
			aria-label="List of options"
			items={[
				{ title: "All", value: "all" },
				{ title: "Option 1", value: "option-1" },
				{ title: "Option 2", value: "option-2" },
			]}
			value={selectedOption}
			onChange={setSelectedOption}
			defaultOption="all"
		/>
	);
};

const WithoutDefaultOptionExample = () => {
	const [selectedOption, setSelectedOption] = useState<number | undefined>();
	return (
		<Select
			aria-label="List of options"
			placeholder="Choose one"
			items={[
				{ title: "Option 1", value: 1 },
				{ title: "Option 2", value: 2 },
			]}
			value={selectedOption}
			onChange={setSelectedOption}
			noDefaultOption
		/>
	);
};

/**
 * @click button
 * */
const WithoutCustomKeysExample = () => {
	const [selectedOption, setSelectedOption] = useState<number | undefined>();
	return (
		<Select
			aria-label="List of fruits"
			items={[
				{
					title: "Apple",
					description: "Red color",
					value: 1,
					key: "red-apple",
				},
				{
					title: "Apple",
					description: "Green color",
					value: 2,
					key: "green-apple",
				},
			]}
			value={selectedOption}
			onChange={setSelectedOption}
		/>
	);
};

const TypeScriptUnionExample = () => {
	type Fruits = "apple" | "banana" | "blueberry" | "cherry";
	const [selectedOption, setSelectedOption] = useState<Fruits | undefined>();
	return (
		<Select
			aria-label="List of fruits"
			placeholder="Choose a fruit"
			items={[
				{ title: "Apple", value: "apple" },
				{ title: "Banana", value: "banana" },
				{ title: "Blueberry", value: "blueberry" },
				{ title: "Cherry", value: "cherry" },
			]}
			value={selectedOption}
			onChange={setSelectedOption}
			noDefaultOption
		/>
	);
};

/**
 * @ignore [a11y:R14]
 * */
const MultiSelectExampleWithMaxNumberOfItems = () => {
	const items = [
		{ title: "Apple", value: "apple" },
		{ title: "Banana", value: "banana" },
		{ title: "Blueberry", value: "blueberry" },
		{ title: "Cherry", value: "cherry" },
	];

	const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

	return (
		<Select
			aria-label="List of fruits"
			items={items}
			value={selectedOptions}
			onChange={setSelectedOptions}
			maxNumberOfItems={2}
		/>
	);
};

/**
 * @click button
 * */
const BulkActionsExample = () => {
	const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
	return (
		<Select
			aria-label="List of fruits"
			bulkActions
			items={[
				{ title: "Apple", value: "apple" },
				{ title: "Banana", value: "banana" },
				{ title: "Blueberry", value: "blueberry" },
				{ title: "Cherry", value: "cherry" },
			]}
			value={selectedOptions}
			onChange={setSelectedOptions}
		/>
	);
};

const CreatableExample = () => {
	const [items, setItems] = useState(["Apple", "Banana", "Blueberry", "Cherry"]);
	const [selectedOption, setSelectedOption] = useState<string[]>([]);
	const itemize = (value: string): OptionItem<string> => ({ title: value, value });

	return (
		<Select
			items={items.map(itemize)}
			value={selectedOption}
			onChange={setSelectedOption}
			creatable
			onCreate={(newValue) => {
				setItems([...items, newValue].sort((a, b) => a.localeCompare(b)));
				return itemize(newValue);
			}}
		/>
	);
};

const CreatableWithValidationExample = () => {
	const [items, setItems] = useState(["Apple", "Banana", "Blueberry", "Cherry"]);
	const [isValid, setIsValid] = useState(true);
	const [selectedOption, setSelectedOption] = useState<string[]>([]);
	const itemize = (value: string): OptionItem<string> => ({ title: value, value });
	const validate = (value: string) => {
		return value.length > 0 && value.length < 10;
	};

	return (
		<FormElementWrapper
			label="Fruit"
			name="Fruit"
			invalid={!isValid}
			error="Value must be between 1 and 10 characters"
			helptext="Pick or create a fruit"
		>
			<Select
				aria-label="List of fruits"
				creatable
				items={items.map(itemize)}
				value={selectedOption}
				onChange={(newValue) => {
					setSelectedOption(newValue);
					setIsValid(true);
				}}
				onCreate={(value: string) => {
					const trimmedValue = value.trim();
					const isValidValue = validate(trimmedValue);
					if (!isValidValue) {
						setIsValid(false);
						return false;
					}

					setIsValid(true);
					setItems([...items, trimmedValue].sort((a, b) => a.localeCompare(b)));
					return itemize(trimmedValue);
				}}
			/>
		</FormElementWrapper>
	);
};

const VariantsExample = () => {
	const [selectedOption, setSelectedOption] = useState<string | undefined>();
	return (
		<Select
			aria-label="List of fruits"
			items={[
				{ title: "Apple", value: "apple-1" },
				{ title: "Apple disabled", value: "apple-2", disabled: true },
				{
					title: "Apple with description",
					description: "Round and usually red or green.",
					value: "apple-3",
				},
				{
					title: "Apple with icon",
					value: "apple-4",
					icon: (
						<Icon>
							<IconLink />
						</Icon>
					),
				},
				{
					title: "Apple with icon and description",
					description: "Round and usually red or green.",
					value: "apple-5",
					icon: (
						<Icon>
							<IconLink />
						</Icon>
					),
				},
				{
					title: "Apple with icon and description disabled",
					value: "apple-6",
					description: "Round and usually red or green.",
					icon: (
						<Icon>
							<IconLink />
						</Icon>
					),
					disabled: true,
				},
			]}
			value={selectedOption}
			onChange={setSelectedOption}
		/>
	);
};

/**
 * @click button
 * */
const GroupsExample = () => {
	const [selectedOption, setSelectedOption] = useState<string[]>([]);
	return (
		<Select
			aria-label="List of fruits"
			searchable="always"
			searchableContent={(item) => item.title + " " + item.description}
			items={[
				{
					heading: "Fruits",
					items: [
						{
							title: "Apple",
							description: "Round and usually red or green.",
							value: "apple",
						},
						{
							title: "Banana",
							description: "Oblong thing that starts out green and becomes yellow, then black.",
							value: "banana",
						},
						{
							title: "Blueberry",
							description: "It’s a berry and it’s blue.",
							value: "blueberry",
						},
						{
							title: "Cherry",
							description: "Like a blueberry, but larger and red.",
							value: "cherry",
						},
					],
				},
				{
					heading: "Vegetables",
					items: [
						{
							title: "Aspargus",
							description: "Easily recognizable for its long pointy spears.",
							value: "aspargus",
						},
						{
							title: "Carrot",
							description: "Orange in color, but purple, black, red, white, and yellow also exist.",
							value: "carrot",
						},
						{
							title: "Cocumber",
							description: "Usually considered a vegetable, however it's botanically a fruit.",
							value: "cocumber",
						},
					],
				},
			]}
			value={selectedOption}
			onChange={setSelectedOption}
		/>
	);
};

const InvalidExample = () => {
	const [selectedOption, setSelectedOption] = useState<string | undefined>();
	return (
		<FormElementWrapper
			label="Fruit"
			name="Fruit"
			invalid
			error="Some error message to let the user know what's wrong"
		>
			<Select
				items={[
					{ title: "Apple", value: "apple" },
					{ title: "Banana", value: "banana" },
					{ title: "Blueberry", value: "blueberry" },
					{ title: "Cherry", value: "cherry" },
				]}
				onChange={setSelectedOption}
				value={selectedOption}
			/>
		</FormElementWrapper>
	);
};

const DisabledExample = () => {
	const [selectedOption1, setSelectedOption1] = useState<string | undefined>();
	const [selectedOption2, setSelectedOption2] = useState<string[]>(["apple", "banana"]);
	return (
		<>
			<Select
				aria-label="List of fruits"
				items={[
					{ title: "Apple", value: "apple" },
					{ title: "Banana", value: "banana" },
					{ title: "Blueberry", value: "blueberry" },
					{ title: "Cherry", value: "cherry" },
				]}
				onChange={setSelectedOption1}
				value={selectedOption1}
				disabled
			/>
			<br />
			<Select
				aria-label="List of fruits"
				items={[
					{ title: "Apple", value: "apple" },
					{ title: "Banana", value: "banana" },
					{ title: "Blueberry", value: "blueberry" },
					{ title: "Cherry", value: "cherry" },
				]}
				onChange={setSelectedOption2}
				value={selectedOption2}
				showSelectionOverview
				disabled
			/>
		</>
	);
};

/** @ignore [a11y:R14] */
const SelectOverviewExample = () => {
	const items = [
		{
			heading: "Fruits",
			items: [
				{
					title: "Apple",
					description: "Round and usually red or green.",
					value: "apple",
					icon: (
						<Icon>
							<IconLink />
						</Icon>
					),
				},
				{
					title: "Banana",
					description: "Oblong thing that starts out green and becomes yellow, then black.",
					value: "banana",
				},
				{
					title: "Blueberry",
					description: "It’s a berry and it’s blue.",
					value: "blueberry",
				},
				{
					title: "Cherry",
					description: "Like a blueberry, but larger and red.",
					value: "cherry",
				},
			],
		},
		{
			heading: "Vegetables",
			items: [
				{
					title: "Aspargus",
					description: "Easily recognizable for its long pointy spears.",
					value: "aspargus",
				},
				{
					title: "Carrot",
					description: "Orange in color, but purple, black, red, white, and yellow also exist.",
					value: "carrot",
				},
				{
					title: "Cocumber",
					description: "Usually considered a vegetable, however it's botanically a fruit.",
					value: "cocumber",
				},
			],
		},
	];
	const [selectedOptions, setSelectedOptions] = useState<string[]>(["apple", "aspargus"]);
	return (
		<Select
			aria-label="List of fruits and vegetables"
			showSelectionOverview
			items={items}
			value={selectedOptions}
			onChange={setSelectedOptions}
		/>
	);
};

/**
 * @click button
 * @ignore [a11y:R14]
 * */
const CustomOptionElementExample = () => {
	const [selectedOptions, setSelectedOptions] = useState<string[]>(["apple", "banana"]);
	return (
		<Select
			aria-label="List of fruits"
			listboxHeading="Shopping list"
			items={[
				{ title: "Apple", value: "apple" },
				{ title: "Banana", value: "banana" },
				{ title: "Blueberry", value: "blueberry" },
				{ title: "Cherry", value: "cherry" },
			]}
			value={selectedOptions}
			onChange={setSelectedOptions}
			optionRenderer={(props) => {
				const { item, onSelect, isSelected } = props;
				return (
					<BaseSelectOption key={item.title} style={{ boxShadow: "none" }} {...props}>
						<Checkbox
							tabIndex={-1}
							disabled={item.disabled}
							onChange={() => onSelect(item)}
							checked={isSelected}
							value={item.title}
						>
							{item.title}
						</Checkbox>
					</BaseSelectOption>
				);
			}}
			showSelectionOverview
			overviewOptionRenderer={(props) => {
				const { item } = props;
				return (
					<BaseOverviewOption key={item.title} {...props}>
						<InlineText>
							Remove <strong>{item.title}</strong> from cart
						</InlineText>
					</BaseOverviewOption>
				);
			}}
		/>
	);
};

/**
 * @click button
 * @ignore [a11y:R14]
 * */
const CustomOptionTitleExample = () => {
	const [selectedOptions, setSelectedOptions] = useState<string[]>(["apple"]);
	const customRenderers = useOptionTitleRenderer<string[]>((title, opt) => (
		<Pill variant={{ type: "static" }} size={opt.isOverview ? "medium" : "small"}>
			<InlineText tone={opt.disabled ? "subtle" : "neutralDark"}>
				<TextHighlight value={title} needle={opt.needle} caseSensitive={opt.caseSensitive} />
			</InlineText>
		</Pill>
	));

	return (
		<Select
			aria-label="List of fruits"
			items={[
				{ title: "Apple", value: "apple" },
				{ title: "Banana", value: "banana" },
				{ title: "Blueberry", value: "blueberry", disabled: true },
				{ title: "Cherry", value: "cherry" },
			]}
			value={selectedOptions}
			onChange={setSelectedOptions}
			showSelectionOverview
			searchable="always"
			{...customRenderers}
		/>
	);
};

/** @ignore [a11y:R14] */
const CustomButtonElementExample = () => {
	const [selectedOption, setSelectedOption] = useState<string | undefined>("blueberry");
	return (
		<Select
			aria-label="List of fruits"
			buttonRenderer={(option?: OptionItem<string>) =>
				option ? (
					<BigSmall
						style={{ textAlign: "start", maxWidth: "80%" }}
						big={option.title}
						small={
							<span
								style={{
									overflow: "hidden",
									textOverflow: "ellipsis",
									whiteSpace: "nowrap",
									display: "block",
								}}
							>
								{option.description}
							</span>
						}
					/>
				) : (
					"Select a fruit"
				)
			}
			buttonProps={{ size: "large" }}
			items={[
				{
					title: "Apple",
					description: "Round and usually red or green.",
					value: "apple",
				},
				{
					title: "Banana",
					description: "Oblong thing that starts out green and becomes yellow, then black.",
					value: "banana",
				},
				{
					title: "Blueberry",
					description: "It’s a berry and it’s blue.",
					value: "blueberry",
				},
				{
					title: "Cherry",
					description: "Like a blueberry, but larger and red.",
					value: "cherry",
				},
			]}
			value={selectedOption}
			onChange={setSelectedOption}
			noDefaultOption
		/>
	);
};

/** @ignore [a11y:R14] */
const CustomSizeExample = () => {
	const [selectedOption, setSelectedOption] = useState<string[]>(["apple", "blueberry"]);
	const commomProps = {
		items: [
			{
				title: "Apple",
				description: "Round and usually red or green.",
				value: "apple",
			},
			{
				title: "Banana",
				description: "Oblong thing that starts out green and becomes yellow, then black.",
				value: "banana",
			},
			{
				title: "Blueberry",
				description: "It’s a berry and it’s blue.",
				value: "blueberry",
			},
			{
				title: "Cherry",
				description: "Like a blueberry, but larger and red.",
				value: "cherry",
			},
		],
		searchable: "always" as const,
		bulkActions: true,
		value: selectedOption,
		onChange: setSelectedOption,
		ariaLabel: "List of fruits",
	};
	return (
		<>
			<Paragraph>Fixed width</Paragraph>
			<Select {...commomProps} width={600} />
			<br /> <br />
			<Paragraph>Fixed width for button and list box</Paragraph>
			<Select {...commomProps} width={600} listboxWidth={600} />
			<br /> <br />
			<Paragraph>Full width</Paragraph>
			<Select {...commomProps} fullWidth />
			<br /> <br />
			<Paragraph>Full width with selection overview</Paragraph>
			<Select {...commomProps} fullWidth showSelectionOverview />
		</>
	);
};

const api: { fruits: { id: number; name: string }[] } = {
	fruits: [
		{ id: 0, name: "Apple" },
		{ id: 1, name: "Banana" },
		{ id: 2, name: "Blueberry" },
		{ id: 3, name: "Cherry" },
		{ id: 4, name: "Grape" },
		{ id: 5, name: "Guava" },
		{ id: 6, name: "Lemon" },
		{ id: 7, name: "Lime" },
		{ id: 8, name: "Orange" },
		{ id: 9, name: "Peach" },
		{ id: 10, name: "Pear" },
		{ id: 11, name: "Pineapple" },
		{ id: 12, name: "Raspberry" },
		{ id: 13, name: "Strawberry" },
		{ id: 14, name: "Watermelon" },
	],
};

const AsyncItemsExample = () => {
	type Fruit = { id: number; name: string };
	const [loading, setLoading] = useState<boolean>(false);
	const [items, setItems] = useState<OptionItem<Fruit>[]>([]);
	const [selectedOption, setSelectedOption] = useState<Fruit | undefined>(api.fruits[1]);

	async function getFruits() {
		await sleep(5000);
		return [...api.fruits];
	}

	function itemize(data: Fruit[]): OptionItem<Fruit>[] {
		return data.map((fruit) => ({
			title: fruit.name,
			value: fruit,
		}));
	}

	useEffect(() => {
		let isSubscribed = true;
		setLoading(true);
		getFruits()
			.then((fruits) => isSubscribed && setItems(itemize(fruits)))
			.finally(() => isSubscribed && setLoading(false));
		return () => {
			isSubscribed = false;
		};
	}, []);

	return (
		<Select
			loading={loading}
			items={items}
			value={selectedOption}
			onChange={setSelectedOption}
			compareFn={(a, b) => a?.id === b?.id}
		/>
	);
};

/**
 * @ignore [a11y:R14]
 * @visualDiffDelay 5000
 * */
const AsyncExample = () => {
	type Fruit = { id: number; name: string };
	let [items, setItems] = useState<OptionItem<Fruit>[]>([]);
	const [selectedOptions, setSelectedOptions] = useState<Fruit[]>([api.fruits[1]]);
	const [loading, setLoading] = useState<boolean>(false);
	const [query, setQuery] = useState<string>("");

	function itemize(data: Fruit[]): OptionItem<typeof selectedOptions>[] {
		return data.map((fruit) => ({
			title: fruit.name,
			value: fruit,
		}));
	}

	function mergeItemsWithSelectedOptions(items: OptionItem<typeof selectedOptions>[]) {
		// merge the list of items with selected options, removing duplicates
		const selectedItems = itemize(selectedOptions);
		return [...items, ...selectedItems].filter(
			(item, index, self) => self.findIndex((i) => i.value.id === item.value.id) === index
		);
	}

	function search<T>(items: T[], mapFn: (x: T) => string, query: string, caseSensitive = false) {
		const q = caseSensitive ? query : query.toLowerCase();
		const map = caseSensitive ? mapFn : (x: T) => mapFn(x).toLowerCase();
		return items.filter((item) => map(item).includes(q));
	}

	async function onSearch(searchQuery: string, caseSensitive: boolean) {
		let fetchedItems: OptionItem<typeof selectedOptions>[] = [];
		if (searchQuery.length === 0) {
			const newItems = itemize(api.fruits.slice(0, 5));
			fetchedItems = mergeItemsWithSelectedOptions(newItems);
		} else {
			await sleep(3000);
			const queryedItems = search(api.fruits, (f) => f.name, searchQuery, caseSensitive);
			fetchedItems = itemize(queryedItems);
		}
		setItems(fetchedItems);
		setLoading(false);
	}

	async function onCreate(value: string) {
		setLoading(true);
		await sleep(3000);
		const newFruit = { id: api.fruits.length, name: value };
		api.fruits.push(newFruit);
		setLoading(false);
		return itemize([newFruit])[0];
	}

	const debouncedSearch = useDebounceFn(onSearch, 500);
	useEffect(() => {
		debouncedSearch(query, false);
	}, [query]);

	if (query && loading) {
		items = search(items, (i) => i.title, query);
	}

	return (
		<Select
			aria-label="List of fruits"
			bulkActions
			creatable
			onChange={setSelectedOptions}
			onCreate={onCreate}
			onSearch={(q) => {
				setLoading(true);
				setQuery(q);
			}}
			loading={loading}
			searchHelpText="Start typing to view and select matching items."
			items={items}
			value={selectedOptions}
		/>
	);
};

/** @ignore [visual] */
const PlacementExample = () => {
	const [showModal, setShowModal] = useState(false);
	const [selectedOptions, setSelectedOptions] = useState<number[]>([]);
	return (
		<>
			<Button onClick={() => setShowModal(true)}>Trigger modal</Button>
			<Modal shown={showModal} headerTitle="Placement Example" onClose={() => setShowModal(false)}>
				<Modal.Content>
					<FormElementWrapper label="Fruit" name="Fruit">
						<Select
							aria-label="List of fruits"
							bulkActions
							placement="auto-start"
							items={[
								{ value: 0, title: "Apple" },
								{ value: 1, title: "Banana" },
								{ value: 2, title: "Blueberry" },
								{ value: 3, title: "Cherry" },
								{ value: 4, title: "Grape" },
								{ value: 5, title: "Guava" },
								{ value: 6, title: "Lemon" },
								{ value: 7, title: "Lime" },
								{ value: 8, title: "Orange" },
								{ value: 9, title: "Peach" },
								{ value: 10, title: "Pear" },
								{ value: 11, title: "Pineapple" },
								{ value: 12, title: "Raspberry" },
								{ value: 13, title: "Strawberry" },
								{ value: 14, title: "Watermelon" },
							]}
							value={selectedOptions}
							onChange={setSelectedOptions}
						/>
					</FormElementWrapper>
				</Modal.Content>
			</Modal>
		</>
	);
};

/** @ignore [a11y:R14] */
const UsageWithDataObserveKeys = () => {
	const [items, setItems] = useState(["Apple", "Banana", "Blueberry", "Cherry"]);
	const [selectedOptions, setSelectedOptions] = useState<string[]>(["banana"]);
	const itemize = (value: string): OptionItem<string> => ({
		title: value,
		value: value.toLowerCase(),
		"data-observe-key": `${value}Item`,
	});

	return (
		<Select
			bulkActions
			showSelectionOverview
			data-observe-key="FruitSelect"
			aria-label="List of fruits"
			items={items.map(itemize)}
			value={selectedOptions}
			onChange={setSelectedOptions}
			creatable
			onCreate={(newValue) => {
				setItems([...items, newValue]);
				return itemize(newValue);
			}}
		/>
	);
};

/** @ignore [a11y:R14] */
const IndeterminateStateExample = () => {
	type Tag = { name: string; sitesCount: number };

	// maximum number of sites
	const totalOfSites = 6;

	// this state simulates the tags list from your API
	const [tagsFromAPI, setTagsFromAPI] = useState<Tag[]>([
		{ name: "Tag 1", sitesCount: 0 },
		{ name: "Tag 2", sitesCount: 2 },
		{ name: "Tag 3", sitesCount: 4 },
		{ name: "Tag 4", sitesCount: 6 },
	]);

	// local copy of the tags list that will be updated with new sites count
	const [tags, setTags] = useState<Tag[]>(tagsFromAPI);
	const [selectedTags, setSelectedTags] = useState<Tag[]>(tags.filter((tag) => tag.sitesCount > 0));

	const onChange = (newSelection: Tag[], updatedTags: Tag[]) => {
		// update the local state
		setSelectedTags(newSelection);

		// submit the changes to your API to update the tag sites count
		console.log("Call your API to update all tag sites count", updatedTags);
		setTagsFromAPI(updatedTags);
	};

	const compareFn = (a: Tag, b: Tag) => a.name === b.name;
	const itemize = (tag: Tag): OptionItem<Tag> => ({
		title: tag.name,
		value: tag,
		description: `${tag.sitesCount} of ${totalOfSites} sites are tagged`,
	});

	const indeterminateStateProps = useSelectIndeterminateState<Tag[]>(
		tagsFromAPI,
		[tags, setTags],
		"sitesCount",
		totalOfSites,
		onChange,
		compareFn
	);

	return (
		<Select
			{...indeterminateStateProps}
			aria-label="List of tags on site"
			items={tags.map(itemize)}
			value={selectedTags}
			showSelectedItemsOnTop={false}
			showSelectionOverview
			bulkActions
			creatable
			onCreate={(value: string) => {
				const newTag = { name: value, sitesCount: totalOfSites };
				setTags([...tags, newTag]);
				return itemize(newTag);
			}}
		/>
	);
};

function useDebounceFn(callback: (...args: any[]) => void, time = 500): (...args: any[]) => void {
	const timeout = useRef<NodeJS.Timeout>();

	useEffect(() => () => timeout.current && clearTimeout(timeout.current), [timeout]);

	return (...args: any[]) => {
		timeout.current && clearTimeout(timeout.current);
		timeout.current = setTimeout(() => {
			callback(...args);
		}, time);
	};
}

function sleep(n: number) {
	return new Promise((resolve) => setTimeout(resolve, n));
}
