import React, { ReactNode, useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { DataComponent, VisualComponent, DataObserveKey, cn } from "@siteimprove/fancylib";
import { stopEvent } from "../../../utils/shorthands";
import { KeyCode } from "../../../utils/keyboard-nav-utils";
import { findOrCreateDialogDropzone } from "../dropzone/dropzone";
import { Trigger } from "../../../utils/dom-utils";

type BaseDialogProps = {
	shown: boolean;
	children: ReactNode;
	onClose: () => void;
	onUnmount?: () => void;
	className?: string;
	noPad?: boolean;
	noCloseOnBackdrop?: boolean;
	"aria-label"?: string;
	"aria-labelledby"?: string;
} & VisualComponent &
	DataComponent &
	DataObserveKey;

export function BaseDialog(props: BaseDialogProps): JSX.Element | null {
	const { className, shown, children, onClose, onUnmount, style, noCloseOnBackdrop } = props;
	const dialogRef = useRef<HTMLDialogElement>(null);
	const mouseDownTarget = useRef<HTMLElement>();
	const trigger = useRef(Trigger.Mouse);
	const [mounted, setMounted] = useState(shown);

	const handleOnClose = () => {
		onClose();
		setMounted(false);
	};

	const handleAnimationEnd = (e: React.AnimationEvent<HTMLDialogElement>) => {
		const target = e.target as HTMLDivElement;
		if (target.nodeName !== "DIALOG") return;
		e.stopPropagation();
		if (!shown) {
			// The type "any" is used because lib.dom.d.ts is not updated to support the native methods of <dialog>
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(dialogRef.current as any)?.close();
		}
	};

	useEffect(() => {
		// The type "any" is used because lib.dom.d.ts is not updated to support the native methods of <dialog>
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		if (shown && mounted) (dialogRef.current as any)?.showModal();
		if (shown && !mounted) setMounted(true);
	}, [shown, mounted]);

	useEffect(() => {
		if (mounted) {
			dialogRef.current?.addEventListener("close", handleOnClose);
			// Used to prevent a scrollbar from appearing when animating the Side Bar component
			document.body.style.overflowX = "hidden";
		} else {
			document.body.style.overflowX = null!;
			onUnmount && onUnmount();
		}
		return () => {
			dialogRef.current?.removeEventListener("close", handleOnClose);
		};
	}, [mounted]);

	const overlayClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
		if (noCloseOnBackdrop) {
			return;
		}

		// Check if the click occurred within the overlay
		if (dialogRef.current !== e.target) {
			return;
		}

		// Check if the mouseDown happened on the same element
		if (mouseDownTarget.current !== e.target) {
			return;
		}

		// Assuming `trigger` and `onClose` are correctly set up
		trigger.current = Trigger.Mouse;
		onClose();
	};

	if (!mounted) {
		return null;
	}

	const dropzone = findOrCreateDialogDropzone();
	if (!dropzone) {
		return null;
	}

	return ReactDOM.createPortal(
		<div
			data-component={props["data-component"] || "base-modal"}
			data-observe-key={props["data-observe-key"]}
			role="region"
			aria-label={props["aria-label"]}
			aria-labelledby={props["aria-labelledby"]}
			onClick={overlayClickHandler}
		>
			<dialog
				ref={dialogRef}
				aria-label={props["aria-label"]}
				aria-labelledby={props["aria-labelledby"]}
				className={cn(className)}
				style={style}
				onAnimationEnd={handleAnimationEnd}
				onKeyDown={(e) => {
					if (e.keyCode === KeyCode.Escape) {
						stopEvent(e);
						onClose();
					}
				}}
				onMouseDown={(e) => {
					if (e.target) {
						mouseDownTarget.current = e.target as HTMLElement; // cast is safe, since onMouseDown guarantees it's a HTMLElement (or subtype thereof)
					}
				}}
			>
				{children}
			</dialog>
		</div>,
		dropzone
	);
}
